<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div class="sign-in">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle">

			<!-- Sign In Form Column -->
			<a-col :span="24" :md="12" :lg="{span: 12, offset: 0}" :xl="{span: 6, offset: 2}" class="col-form">
				<h1 class="mb-15">{{$t('auth.login.btn')}}</h1>
				<h5 class="font-regular text-muted">{{$t('auth.login.description')}}</h5>

				<!-- Sign In Form -->
				<a-form
					id="components-form-demo-normal-login"
					:form="form"
					class="login-form"
					@submit="handleSubmit"
					:hideRequiredMark="true"
				>
					<a-form-item class="mb-10" :label="$t('auth.login.email.placeHolder')" :colon="false">
						<a-input 
						v-decorator="[
						'email',
						{ rules: [{ required: true, message: $t('auth.login.email.required') }] },
						]" :placeholder="$t('auth.login.email.placeHolder')" />
					</a-form-item>
					<a-form-item class="mb-5" :label="$t('auth.login.password.placeHolder')" :colon="false">
						<a-input
						v-decorator="[
						'password',
						{ rules: [{ required: true, message:  $t('auth.login.password.required') }] },
						]" type="password" :placeholder="$t('auth.login.password.placeHolder')" />
					</a-form-item>
					<a-form-item>
						<a-button :loading="loading" type="primary" block html-type="submit" class="login-form-button">
							{{$t('auth.login.btn')}}
						</a-button>
					</a-form-item>
				</a-form>
				<!-- / Sign In Form -->

				<p class="font-semibold text-muted">
					<router-link to="/sign-up" class="font-bold text-dark">{{$t('auth.login.registerBtn')}}</router-link> | 
					<router-link to="/forgot" class="font-bold text-dark">{{$t('auth.login.forgotPasswordBtn')}}</router-link>
				</p>
			</a-col>
			<!-- / Sign In Form Column -->

			<!-- Sign In Image Column -->
			<a-col :span="24" :md="12" :lg="12" :xl="12" class="col-img">
				<img src="images/img-signin.jpg" alt="">
			</a-col>
			<!-- Sign In Image Column -->

		</a-row>
		
	</div>
</template>

<script>
	import authService from '@/services/auth'
	import { notification } from 'ant-design-vue';
	import {setAccessToken, setUserInfo} from '@/plugins/storage'
	import { STATUS_ACTIVE } from '@/constant';

	export default ({
		data() {
			return {
				// Binded model property for "Sign In Form" switch button for "Remember Me" 
				email: '',
				password : '',
				rememberMe: true,
				loading: false
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		methods: {
			// Handles input validation after submission.
			handleSubmit(e) {
				this.loading = true
				e.preventDefault();
				this.form.validateFields(async (err, values)=> {
					if ( !err ) {
						let formData = new FormData();
						formData.append('email', values.email);
						formData.append('password', values.password);

						const res = await authService.login(formData)
						if(res.status == STATUS_ACTIVE){
							setAccessToken(res.data.access_token)
							setUserInfo(res.data.user)
							this.$router.push({ name: 'home' })
						} else {
							notification.error({
								message: res.message
							});
						}
					}
					this.loading = false
				});
			},
		},
	})
</script>